.faq {
    width: 100%;
    scroll-behavior: smooth;
}

.faq_head {
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg5.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.faq_head .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.faq_head_text {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.faq_head_text h1 {
    font-size: 2.5em;
    margin: 0;
}

.faq_main {
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.faq_item {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.faq_question {
    font-size: 1.2em;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq_answer {
    display: none;
    text-align: left;
    margin-top: 10px;
}

.faq_answer p {
    margin: 5px 0;
}

.active {
    display: block;
}

@media (max-width: 600px) {
    .faq_head_text h1 {
        font-size: 2em;
    }

    .faq_main {
        padding: 10px;
    }

    .faq_question {
        font-size: 1em;
    }
}
