.testimonials_section {
  padding: 50px 20px;
  background-color: #082750;
}

.heading {
  text-align: center;
  color: white;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
}

.content_box {
  width: 100%;
  color: white;
  margin-right: 10px;

}

.content_box h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.content_box p {
  font-size: 1rem;
  line-height: 1.6;
}

.frame {
  width: 100%;
  max-width: 800px;
  padding: 10px;
  box-shadow: rgba(255, 255, 255, 0.5) 0px 4px 12px;
  background-color: black;
  border-radius: 10px;
}

.video {
  width: 100%;
  border-radius: 5px;
}

@media only screen and (min-width: 768px) {
  .flexbox {
    flex-direction: row;
  }

  .content_box {
    width: 50%;
    padding: 0;
    margin-bottom: 0;
  }

  .frame {
    width: 50%;
  }
}
