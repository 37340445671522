.visionmission {
    width: 100%;
}

.visionmission_head {
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg3.webp') no-repeat center center/cover;
    background-attachment: fixed;
    position: relative;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0%);
}

.visionmission_head_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.visionmission_head_text h1 {
    color: white;
    white-space: nowrap;
}

.vision_flexbox {
    width: 90%;
    margin: auto;
    display: flex;
    height: 80vh;
    gap: 50px;
    padding: 20px 0;
}

.vision_box {
    width: 100%;
    height: 400px;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    background-color: white;
    margin: auto;
    display: flex;
    align-items: center;
}

.vision_box h2 {
    background-color: red;
    width: fit-content;
    padding: 10px 30px;
    border-radius: 100px;
    color: white;
    margin: 20px 0;
}

.main_content p {
    line-height: 30px;
}

.points {
    list-style: none;
    padding: 0;
}

.list_styles {
    display: flex;
    align-items: center;
    margin: 10px 0;
    position: relative;
    padding-left: 20px;
}

.list_styles img {
    width: 20px;
    margin-right: 10px;
}

@media only screen and (max-width: 768px) {
    .vision_flexbox {
        flex-direction: column;
        gap: 30px;
        height: auto;
    }
    .vision_box {
        width: 100%;
        height: auto;
    }
}
