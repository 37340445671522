.goToTopButton {
    position: fixed;
    bottom: 100px;
    right: 43px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background-color: red;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out;
  }
  
  .goToTopButton.visible {
    opacity: 1; /* Visible when scrolled down */
  }
  
  .goToTopButton.hidden {
    display: none; /* Hidden when not scrolled down */
  }
  