.footer {
  width: 100%;
  background-color: #F1F6F5;
  padding: 40px 0;
  background: url('../../Assets/footer.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 50px;
}

.bold{
  font-weight: bold;
}
.footer_main_flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin: auto;
  gap: 20px;
}

.footer_box {
  flex: 1;
  min-width: 250px;
  text-align: left;
  text-align: center;
}

.footer_about {
  text-align: left;
}

.logo_container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 50px;
  margin-right: 10px;
}

.social_icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.social_icons a {
  color: inherit;
  text-decoration: none;
}

.footer_links {
  list-style-type: none;
  padding: 0;
}

.footer_links li {
  margin-bottom: 10px;
  color: black;
}

.footer_links_main {
  text-decoration: none;
  color: black;
}

.footer_links_main:hover {
  color: red;
}

.footer_form {
  text-align: left;
}

.footer_form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 100px;
}

.footer_form textarea{
  padding: 10px;
  width: 100%;
}

.footer_form textarea {
  height: 100px;
  border-radius: 10px;
}

.footer_form button {
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 100px;
}

.footer_form button:hover {
  background-color: #b30000;
}

.footer_bottom {
  background-color: rgb(0, 0, 46);
  color: white;
  text-align: center;
  padding: 10px 0;
}

@media only screen and (max-width: 768px) {
  .footer_main_flex {
    flex-direction: column;
    align-items: start;
  }
  .footer_box{
    text-align: start;
  }

  .footer_box {
    margin-bottom: 20px;
  }
}
