.clubs {
    width: 100%;
    scroll-behavior: smooth;
  }
  
  .club_head {
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg6.webp') no-repeat center;
    background-size: cover;
    position: relative;
  }
  
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: linear-gradient(186deg, rgba(255, 255, 255, 1) 0%, rgba(0, 34, 92, 1) 0);
  }
  
  .clubs_head_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
  }
  
  .clubs_main {
    width: 90%;
    max-width: 1200px; /* Adjust max-width as per your design */
    margin: auto;
    padding: 50px 0;
  }
  
  .clubs_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .club_grid_box {
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    overflow: hidden; /* Ensures images don't overflow */
    transition: transform 0.3s ease;
  }
  
  .club_grid_box:hover {
    transform: scale(1.05); /* Slightly scale up on hover */
    border: 1px solid red;
  }
  
  .club_grid_box img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover; /* Ensures images fill the container */
  }
  
  .club_grid_box h2 {
    font-size: 22px;
    margin-top: 15px;
    color: #333; /* Adjust text color */
    text-align: center;
  }
  
  .club_grid_box p {
    font-size: 16px;
    line-height: 1.6;
    color: #666; /* Adjust text color */
    text-align: justify;
  }
  
  @media only screen and (max-width: 768px) {
    .clubs_grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  
    .club_grid_box {
      width: 100%;
    }
  }
  