.campus {
    width: 100%;
    scroll-behavior: smooth;
}

.campus_head {
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg3.webp') no-repeat center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255, 255, 255);
    background: linear-gradient(186deg, rgba(255, 255, 255, 1) 0%, rgba(0, 34, 92, 1) 0);
}

.campus_head_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
}

.campus_text h2 {
    color: red;
}

.campus_main {
    width: 100%;
}

.campus_flex {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: auto;
    width: 90%;
    padding: 100px 50px;
}

.campus_text {
    flex: 1;
    margin-right: 50px;
}

.campus_text p {
    line-height: 1.6;
    text-align: justify;
}

.campus_images {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.images_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.images_grid img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

@media only screen and (max-width: 768px) {
    .campus_flex {
        flex-direction: column;
        width: 100%;
        padding: 50px 20px;
        margin: 0;
    }

    .campus_text {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .images_grid {
        width: 100%;
    }

    .images_grid img {
        width: 100%;
        height: auto;
    }
}
