.introduction {
    margin-top: 20px;
    background: url('../../../Assets/Home/a_bg_1.webp') no-repeat;
    background-position: 0 -200px;
    background-size: cover;
    position: relative;
    z-index: 10;
    background-attachment: fixed;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 92);
    opacity: 0.6;
    z-index: -10;
}

.flex_history {
    display: flex;
    align-items: center;
    margin: auto;
    padding: 100px;
    flex-wrap: wrap;
}

.box {
    width: 100%;
    color: white;
    margin: 20px 0;
}

.box h2 {
    font-size: 35px;
}

.box p {
    line-height: 35px;
}

.main_button {
    padding: 10px 30px;
    border-radius: 100px;
    outline: none;
    border: none;
    background-color: red;
    color: white;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-decoration: none;
    display: inline-block;
    margin-top: 20px;
}

.main_button:hover {
    background-color: rgb(106, 0, 0);
}

@media only screen and (max-width: 800px) {
    .introduction {
        background-position: center;
    }

    .flex_history {
        flex-direction: column;
        padding: 50px 20px;
    }
}
