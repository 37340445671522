.curriculum{
width: 100%;
scroll-behavior: smooth;
}

.curriculum_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg5.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}
.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}
.overlay2{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.curriculum_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-wrap: nowrap;
}

.curriculum_main{
    width: 100%;
}

.curriculum_box{
    width: 80%;
    display: flex;
    margin: auto;
    padding-top: 20px;
    align-items: center;
}
.curriculum_box_head{
    width: 100%;
}
.curriculum_box img{
    width: 100%;
}

.curriculum_box_content{
    width: 100%;
    padding-left: 20px;
}
.curriculum_box_content h2{

    font-size: 34px;
    color: red;
}
.curriculum_box_content p{
    line-height: 30px;
}


.curriculum_extra{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg3.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.curriculum_extra_text{
    width: 80%;
    position: absolute;
    top: 50%;left: 50%;transform: translate(-50%, -50%);
}
.curriculum_extra_text h1{
    font-weight: 500;
    color: white;
}
.curriculum_extra_text p{
    color: white;
    font-weight: 400;
    line-height: 30px;
}

@media only screen and (max-width:900px) {  
    .curriculum_box_head{
        width: 100%;
    }
    .curriculum_box_content{
        width: 80%;
    }
    .curriculum_box_content p{
        line-height: 25px;
    }
    
}

@media only screen and (max-width:768px) {
    .curriculum_box{
        flex-direction: column;
    }

    .curriculum_box_content{
        width: 100%;
        padding: 0;
    }
    .curriculum_box_reverse{
        flex-direction: column-reverse;
    }
    .curriculum_extra{
        height: 90vh;
    }
    .curriculum_extra_text{
       width: 90%;
      margin: auto;
    }
}