.testSchedule {
  font-family: Arial, sans-serif;
  color: #333;
  scroll-behavior: smooth;
}

.testSchedule_head {
  width: 100%;
  height: 50vh;
  background: url('../../../Assets/Home/school-2.webp') no-repeat center;
  background-size: cover;
  position: relative;
}

.testSchedule_head .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: rgb(255, 255, 255);
  background: linear-gradient(186deg, rgba(255, 255, 255, 1) 0%, rgba(0, 34, 92, 1) 0);
}

.testSchedule_head_text {
  position: absolute;
  color: #fff;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 1;
  text-align: center;
  width: 90%;
}

.testSchedule_head_text h1 {
  font-size: 2.5em;
  margin: 0;
}

.testSchedule_main {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testSchedule_table {
  width: 90%;
  max-width: 800px;
  margin: auto;
  border-collapse: collapse;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testSchedule_table th, .testSchedule_table td {
  padding: 15px;
  text-align: center;
  border: 1px solid #ddd;
}

.testSchedule_table th {
  background-color: #333;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.testSchedule_table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.testSchedule_table tr:hover {
  background-color: #f1f6f5;
}

.testSchedule_table td {
  color: #333;
}

.testSchedule_table td:first-child {
  color: red;
  font-weight: bold;
}

@media (max-width: 768px) {
  .testSchedule_table th, .testSchedule_table td {
    padding: 10px;
  }

  .testSchedule_head_text h1 {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .testSchedule_head {
    height: 40vh;
  }

  .testSchedule_head_text h1 {
    font-size: 1.8em;
  }

  .testSchedule_table th, .testSchedule_table td {
    font-size: 14px;
    padding: 8px;
  }

  .testSchedule_table td:first-child {
    font-size: 14px;
  }
}
