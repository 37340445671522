.academics{
 width: 100%;
 scroll-behavior: smooth;
}

.academics_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg5.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}
.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}
.academics_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}


.academics_main{
    width: 100%;

}

.academics_main_content{
    padding: 100px;
}
.academics_main_content h2{
    font-size: 30px;
    color: red;
}
.academics_main_content p{
    line-height: 30px;
}


@media only screen and (max-width:768px) {
       .academics_main_content{
        padding: 0;
        width: 90%;
        margin: auto;
        padding-top: 50px;
       }
}