/* Home.module.css */

.marquee {
    background-color: #000000; /* Red background */
    color: white;
    padding: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    overflow: hidden; /* Hide overflow for continuous scrolling */
  }
  
  .marquee p {
    white-space: nowrap; /* Prevent text wrapping */
    animation: marquee 20s linear infinite; /* Adjust speed and duration */
  }
  
  @keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }
  