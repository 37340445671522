.rules{
    width: 100%;
    scroll-behavior: smooth;
}
.rules_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg5.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;

}

.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);

}   
.rule_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-wrap: nowrap;
}   

.rule_main{
    width: 100%;
}
.rule_general_discipline{
    width: 100%;

}
.general_discipline_inner{
    width: 80%;
    margin: auto;
}

.generalDrop{
    display: none;
}

.generaldropopen{
    display: block;
}
.margin_for{
    margin: 50px;
}

.links{
    text-decoration: none;
    color: rgb(77, 77, 77);
    
}
.rule_list{
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px 20px;
    margin: 10px;
}
.rule_list h1{
    color: red;
}
.rules_start{
    padding: 20px;
    list-style:none;
    border-bottom: 1px solid gray;
}


/* librarydrop */
.librarydrop{
    display: none;

}
.librarydropopen{
    display: block;
}

/* recommendation*/
.recommendationdrop{
    display: none;
}
.recommendationdropopen{
    display: block;
}



@media only screen and (max-width:900px) {
    .general_discipline_inner{
        width: 100%;

    }
    .margin_for{
        margin:  30px 5px;
    }
}