.contact {
  font-family: 'Arial', sans-serif;
  background-color: #f1f6f5;
}

.contact_head {
  width: 100%;
  height: 300px;
  background: url('../../Assets/Home/school-2.webp') no-repeat center;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: rgb(255,255,255);
  background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.contact_head_text {
  position: relative;
  color: #fff;
  text-align: center;
}

.contact_head_text h1 {
  font-size: 3em;
  margin: 0;
  animation: fadeInDown 1s ease-in-out;
}

.contact_main {
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.form_container {
  flex: 1;
  padding: 20px;
  min-width: 300px;
  max-width: 500px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px;
}

.contact_form {
  display: flex;
  flex-direction: column;
}

.contact_form h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #00225c;
}

.form_group {
  margin-bottom: 15px;
}

.form_group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form_group input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.form_group input:focus {
  border-color: #ff0000;
}

.submit_button {
  padding: 12px 20px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  text-transform: uppercase;
}

.submit_button:hover {
  background-color: #b30000;
}

.details_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px;
}

.contact_details {
  display: flex;
  flex-direction: column;
}

.details_box {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.details_box i {
  padding: 20px;
  background-color: red;
  border-radius: 50%;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.details_box p {
  margin: 0 0 0 20px;
  font-size: 20px;
}

.map {
  height: 300px;
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .contact_head {
    height: 200px;
  }

  .contact_head_text h1 {
    font-size: 2em;
  }

  .form_container, .details_container {
    margin: 10px;
    min-width: 100%;
  }

  .map {
    height: 200px;
  }
}

@media (max-width: 480px) {
  .contact_head {
    height: 150px;
  }

  .contact_head_text h1 {
    font-size: 1.5em;
  }

  .submit_button {
    padding: 10px;
  }

  .details_box p {
    font-size: 16px;
  }

  .map {
    height: 150px;
  }
}
