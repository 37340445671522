.onlineadmission{
    width: 100%;
    scroll-behavior: smooth;
}
.onlineadmission_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg4.webp')no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.onlineadmission_head_text{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.onlineadmission_head_text h1{
    color: white;
}

.onlineadmission_main{
    padding-top: 50px;
    width: 100%;
    padding-bottom: 50px;
}
.onlineadmission_download_form{
    width: 90%;
    margin: auto;
}
.onlineadmission_download_form h1{
    color: red;
}

.download_form_button{
    padding: 10px 20px;
    border-radius: 100px;
    outline: none;
    border: none;
    background-color: red;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: white;
}
.download_form_button:hover{
    background-color: rgb(143, 2, 2);
}
.border_bottom{
    width:100%;
    height: 5px;
    background-color: red;
    animation: leftToRight 2s forwards;
}

@keyframes leftToRight {
 0%{
    transform: translateX(-100%);

 }  
 100%{
    transform: translateX(0);
 }  
}
@media only screen and (max-width:900px) {
    .onlineadmission_head_text{
        text-wrap: nowrap;
    }
}

/* bankers */

.bankers{
    display: flex;
    width: 90%;
    margin: auto;
}

.banker_main{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}
.banker_main_head h1{
    text-align: center;
    
    color: red;
}

.banker_main_flex{
    width: 80%;
    margin: auto;
   
    padding-top:50px;
    margin: auto;
}
.banker_main_box {
    text-align: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    margin:30px auto ;
    border-radius: 10px;
   
}
.banker_main_box i{
    font-size: 40px;
}
.bankdetails{
    text-align: start;
    padding: 20px 0;
}
.bankdetails li{
    margin: 10px;
}

.font_color{
    color: red;
    font-weight: bold;
}




.payment_confirmation_form{
    width: 100%;
}
.payment_confirmation_head{
    text-align: center;
}
.payment_confirmation_head h1{
    font-weight: 500;
}


.main_form{
    width: 80%;
    margin: auto;
}

.formContainer {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.formGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

input, select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

input[type="file"] {
    padding: 0;
}

.submitButton {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 100px;
    background: #ff0000;
    color: white;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
}

.submitButton:hover {
    background: #b30000;
}
@media only screen and (max-width:900px) {
    .bankers{
        flex-direction: column;
    }
    .banker_main_flex{
        width: 100%;
        flex-direction: column;
    }
    .banker_main_box{
        padding: 10px;
        margin: 10px 0;
    }
}




/* documents */

.documents{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
    margin-top: 50px;
    border-radius: 10px;
    padding: 20px;
}
.documents_inner h2{
    color: #ff0000;
    font-size: 30px;
}
.document_list li{
    list-style: none;
    margin: 30px;

}