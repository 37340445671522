.events{
    width: 100%;
    padding: 20px;

}
.events_head{
    width: 100%;
    height: 200px;
    background: url('../../../Assets/Home/school-2.webp') no-repeat center;
    background-size: cover;
    position: relative;
}
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.event_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.events_main{
    width: 100%;
    height: 50vh;
    margin: auto;
}
.event_mian_head{
    margin: auto;
}
.top_calendar{
    width: 30%;
    margin: auto;
}