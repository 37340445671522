.sliderContainer {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.slide {
    width: 100%;
    height: 100vh;
    position: relative;
}

.slide1 {
    width: 100%;
    background: url('../../../Assets/Home/Web_Banner_01.webp') no-repeat center;
    background-size: contain;
}

.slide2 {
    background: url('../../../Assets/Home/Web_Banner_2.webp') no-repeat center;
    background-size: contain;
}

.slide3 {
    background: url('../../../Assets/Home/Web_Banner_3.webp') no-repeat center;
    background-size:contain;  
}

.slide_content {
    position: absolute;
}

.slide_content1 {
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
}
.slide_content1 h1{
    color: white;
    font-size: 40px;
}
.slide_content1 h2{
    color: red;
    font-size: 30px;
    font-weight: 600;
}
.slide_content1 h3{
    color: red;
    font-size: 18px;
    font-weight: 500;
}

.slide_content2 {
    top: 20%;
    left: 20%;
}

.slide_content2{
    width: 100%;
    top: 50%;
    right: 0;
    left: 45%;
    text-align: end;
    transform: translate(-50%, -50%);
}

.slide_content2 h1{
    color: white;
    font-size: 40px;
}
.slide_content2 h2{
    color: red;
    font-size: 30px;
    font-weight: 600;
}
.slide_content2 h3{
    color: red;
    font-size: 18px;
    font-weight: 500;
}



/* Responsive Code  */

@media only screen and (max-width:1090px) {
    .slide_content1{
        top: 50%;
        left: 25%;
    }  
}

@media only screen and (max-width:900px) {
    .slide_content1{
        top: 40%;
        left: 30%;
    }
    
}

@media only screen and (max-width:768px) {
    .sliderContainer{
        height:auto;
    }
    .slide{
        height: 30vh;
        
    }
    .slide_content1{
        top: 40%;
        left: 35%;
    }
    .slide_content1 h1{
        font-size: 30px;
    }
   
}