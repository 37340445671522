.testimonials {
    width: 100%;
}

.testimonials_head {
    width: 100%;
    height: 50vh;
    background: url('../../Assets/Home/school-2.webp') no-repeat center;
    background-size: cover;
    position: relative;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background: rgb(255, 255, 255);
    background: linear-gradient(186deg, rgba(255, 255, 255, 1) 0%, rgba(0, 34, 92, 1) 50%);
}

.testimonials_head_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.testimonials_main {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: #f1f6f5;
}

.video_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(354px, 1fr));
    gap: 20px;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 20px;
}

iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
}
