header{
    width: 100%;   
}

/* Top bar  */
.top_bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 10px 7%;
    background-color: #EC1C24;
}
.tob_bar_inner li{
    list-style: none;
    margin:0 10px;
    color: #e1e1e1;
}
.tob_bar_inner{
    display: flex;
    align-items: center;
}
.margin{
    margin:0 5px;
}
.margin a{
    text-decoration: none;
    color: rgb(225, 225, 225);
}
.margin a:hover{
    color: white;
}
.item1{
    display: flex;
    align-items: center;
    color: rgb(225, 225, 225);
}

.item2 {
    display: flex;
    align-items: center;
    color: rgb(225, 225, 225);
}

.download_form_button{
    padding: 10px 15px;
    border-radius: 100px;
    border: none;
    outline: none;
    background-color: white;
    color: black;
    font-weight: 600;
}
.download_form_button:hover{
    background-color: black;
    color: white;
}



/* Main Navbar */
.nav_wrapper{
    width: 100%;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
   
}
.navbar{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  
}
.logo{
    display: flex;
    align-items: center;
    
}
.logo img{
    width: 90px;
}
.logo p{
 font-size: 10px;
 color: #7a7a7a;
}
.bars{
    display:none;
    width: 30px;
    height: 30px;
}

.bar{
    width: 30px;
    height: 3px;
    margin: 5px 0;
    background-color: black;
}
.cross .bar:nth-child(1){
    transform: rotate(45deg) translate(5px , 5px);
}
.cross .bar:nth-child(2){
    opacity: 0;
}
.cross .bar:nth-child(3){
    transform: rotate(-45deg) translate(5px , -5px);
}

.nav_links .links{
    margin: 10px;
    font-size: 14px;
    text-decoration: none;
    color: #5F5F5F;
    font-weight: 500;
    position: relative;
}




/* DropDowns */


/* About DropDown  */
.aboutDropdown{
    display: none;
}
.aboutDropdownopen{
    padding: 10px;
    text-align: center;
    top: 20px;
    left: -25px;
    display: block;
    position: absolute;
    background-color: whitesmoke;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1000;
}

.aboutDropdown li{
    list-style: none;
    width: 100%;
    padding: 5px 0;
}
.aboutDropdownopen li:hover{
    background-color: white;
    border-radius: 5px;
}

/* Admission */

.admissionDropdown{
    display: none;
}
.admissionDropdownOpen{
    padding: 10px;
    text-align: center;
    top: 20px;
    left: -25px;
    display: block;
    position: absolute;
    background-color: whitesmoke;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1000;
}
.admissionDropdownOpen li{
    list-style: none;
    width: 100%;
    padding: 5px 0;
}
.admissionDropdownOpen li:hover{
    background-color: white;
    border-radius: 5px;
}

/* Learning */

.learningDropdown{
    display: none;
}
.learningDropdownOpen{
    padding: 10px;
    text-align: center;
    top: 20px;
    left: -25px;
    display: block;
    position: absolute;
    background-color: whitesmoke;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1000;
}
.learningDropdownOpen li{
    list-style: none;
    width: 100%;
    padding: 5px 0;
}
.learningDropdownOpen li:hover{
    background-color: white;
    border-radius: 5px;
}


/* activities */
.activitiesDropdown{
    display: none;
}
.activitiesDropdownOpen{
    padding: 10px;
    text-align: center;
    top: 20px;
    left: -25px;
    display: block;
    position: absolute;
    background-color: whitesmoke;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1000;
}
.activitiesDropdownOpen li{
    list-style: none;
    width: 100%;
    padding: 5px 0;
}
.activitiesDropdownOpen li:hover{
    background-color: white;
    border-radius: 5px;
}


/* What's happne */

.whatDropdown{
    display: none;
}
.whatDropdownOpen{
    padding: 10px;
    text-align: center;
    top: 20px;
    left: -25px;
    display: block;
    position: absolute;
    background-color: whitesmoke;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1000;
}
.whatDropdownOpen li{
    list-style: none;
    width: 100%;
    padding: 5px 0;
}
.whatDropdownOpen li:hover{
    background-color: white;
    border-radius: 5px;
}

/* Mandatory Documents */
.mandatoryDropdown{
    display: none;
}
.mandatoryDropdownOpen{
    padding: 10px;
    text-align: center;
    top: 20px;
    left: -25px;
    display: block;
    position: absolute;
    background-color: whitesmoke;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    z-index: 1000;
}
.mandatoryDropdownOpen li{
    list-style: none;
    width: 100%;
    padding: 5px 0;
}
.mandatoryDropdownOpen li:hover{
    background-color: white;
    border-radius: 5px;
}


@media only screen and (max-width:1400px) {
    .top_bar{
        display: none;
    }
    .navbar{
        width: 90%;
        display: flex;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;
        
    }
    
    .nav_links{
        display: none;
        
    }
    .main_menu_open{
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 250px;
        height: 100%;
        transition: left 0.3s ease;
        background-color: #000000;
        animation: LeftIn 0.5s forwards;
        z-index: 1000;
    }
    .main_menu_open .links{
        color: rgb(255, 255, 255);
        font-size: 14px;
        padding: 10px;
        
    }
    .main_menu_open .links:hover{
        color: white;
    }
    .bars{
        display: block;
        padding:0 ;
    }
 


    /* Dropdown */

    /* About */
    .aboutDropdownopen{
        position: relative;
        top: 10px;
        background-color: transparent;
        margin: auto;
        color: white;
    }
    .aboutDropdownopen .links{
        padding: 0;
        margin: 0;
    }
    .aboutDropdownopen li{
        color: white;
    }
    .aboutDropdownopen li:hover{
        background-color: transparent;
        color: red;
    }

/* Admission */
    .admissionDropdown{
        position: relative;
        top: 10px;
        background-color: #000000;
       margin: auto;
    }
    .admissionDropdownOpen .links{
        padding: 0;margin: 0;
    }

     .admissionDropdown li{
        color: white;
    }
    .admissionDropdown li:hover{
        background-color: transparent;
        color: red;
    }

    /* Learning */
    .learningDropdown{
        position: relative;
        top: 10px;
        background-color: #000000;
        margin: auto;
    }
    .learningDropdownOpen .links{
        padding: 0;margin: 0;
    }

     .learningDropdown li{
        color: white;
    }
    .learningDropdown li:hover{
        background-color: transparent;
        color: red;
    }
    
    /* activities */

    .activitiesDropdown{
        position: relative;
        top: 10px;
        background-color: #000000;
        margin: auto;
    }
    .activitiesDropdownOpen .links{
        padding: 0;margin: 0;
    }

     .activitiesDropdown li{
        color: white;
    }
    .activitiesDropdown li:hover{
        background-color: transparent;
        color: red;
    }

    /* What's Happenning  */
    .whatDropdown{
        position: relative;
        top: 10px;
        background-color: #000000;
       margin: auto;
    }

     .whatDropdown li{
        color: white;
    }
    .whatDropdown li:hover{
        background-color: transparent;
        color: red;
    }

    /* Mandatory Discloser */
    .mandatoryDropdown{
        position: relative;
        top: 10px;
        background-color: #000000;
       margin: auto;
    }

     .mandatoryDropdown li{
        color: white;
    }
    .mandatoryDropdown li:hover{
        background-color: transparent;
        color: red;
    }

    
    /* Keyframe */
    @keyframes LeftIn {
        0%{
            transform: translateX(-100%);
        }
        
    }   
}