.schoolhistory {
  width: 100%;
}

.schoolhistory_head {
  width: 100%;
  height: 50vh;
  background: url('../../../Assets/bg3.webp') no-repeat center center/cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0%);
}

.schoolhistory_head_text {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 10%;
  transform: translate(-50%, -50%);
}

.schoolhistory_head_text h2 {
  color: red;
  font-weight: 400;
}

.schoolhistory_head_text h1 {
  color: white;
  font-size: 40px;
}

.schoolhistory_maincontent {
  width: 100%;
}

.schoolhistory_section {
  padding: 50px 20px;
}

.schoolhistory_section_title h2 {
  color: red;
  text-align: center;
  font-size: 30px;
}

.schoolhistory_section_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scrollenvelope {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.scrollenvelope video {
  width: 80%;
  max-width: 800px;
  height: auto;
}

@media only screen and (max-width: 900px) {
  .schoolhistory_head {
    height: 70vh;
    background-attachment: fixed;
    background-position: -400px -100px;
  }

  .schoolhistory_head_text {
    left: 30%;
  }

  .schoolhistory_section {
    overflow: hidden;
  }

  .scrollenvelope {
    width: 100%;
    transform: scale(1.2);
  }

  .scrollenvelope video {
    width: 100%;
  }
}
