.news {
  background-color: #f0f0f0;
  padding: 20px;
}

.news_head {
  width: 100%;
  height: 200px;
  background: url('../../../Assets/Home/school-2.webp') no-repeat center;
  background-size: cover;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.news_head_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.news_tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.news_tabs button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
}

.news_tabs button:hover {
  background-color: #ccc;
}

.news_tabs .active {
  background-color: red;
  color: white;
}

.news_main {
  margin-top: 20px;
  width: 80%;
  margin: auto;
}

.news_main h1 {
  color: red;
}

.event {
  display: flex;
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.event_image {
  flex: 1;
}

.event_image img {
  width: 100%;
  height: auto;
  display: block;
}

.event_details {
  flex: 2;
  padding: 20px;
  background-color: #ffffff;
}

.event_details h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
}

.event_date {
  font-style: italic;
  color: #888888;
  margin-bottom: 10px;
}

.event_description {
  line-height: 1.6;
}

.readmore {
  cursor: pointer;
  color: red;
  transition: color 0.3s ease;
}

.readmore:hover {
  color: #003064; /* Change color on hover */
}

.readmore_arrow {
  margin-left: 5px; /* Adjust spacing */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .news_head {
    height: 150px; /* Adjust height for smaller screens */
  }

  .news_head_text {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .news_main {
    width: 90%; /* Adjust width for smaller screens */
  }

  .event {
    flex-direction: column; /* Stack events vertically on smaller screens */
  }

  .event_details {
    padding: 15px; /* Decrease padding for smaller screens */
  }

  .event_description {
    font-size: 14px; /* Adjust font size for description on smaller screens */
  }
}
