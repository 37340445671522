.career {
    width: 100%;
    height: 100vh;
    background: url('../../Assets/join-the-team.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.content {
    width: 90%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.vacancies, .applyForm {
    width: 45%;
}

.vacancies h2, .applyForm h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.vacancies ul {
    list-style-type: none;
    padding: 0;
}

.vacancies li {
    margin-bottom: 20px;
}

.vacancies h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.vacancies p, .formGroup label {
    font-size: 16px;
    margin-bottom: 5px;
}

.applyForm form {
    display: flex;
    flex-direction: column;
}

.formGroup {
    margin-bottom: 15px;
}

.formGroup input,
.formGroup textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.formGroup input[type="file"] {
    padding: 5px;
}

button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #b30000;
}

@media (max-width: 768px) {
.career {
    height: auto;
}
    .content {
        flex-direction: column;
        align-items: center;
    }

    .vacancies, .applyForm {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media (max-width: 480px) {
    .vacancies h2, .applyForm h2 {
        font-size: 20px;
    }

    .vacancies h3, .formGroup label {
        font-size: 18px;
    }

    .vacancies p, .formGroup input, .formGroup textarea, button {
        font-size: 14px;
    }
}
