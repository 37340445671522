.why_hillgreen {
  width: 100%;
  padding: 50px 0;
  background-color: #f9f9f9;
}

.why_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
  padding: 50px;
  gap: 30px;
}

.why_box {
  flex: 1;
  margin: auto;
}

.why_image_holder {
  text-align: center;
}

.why_image_holder img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.why_image_holder img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.why_content {
  padding-left: 20px;
  color: #333;
}

.why_content h2 {
  font-weight: 700;
  margin-bottom: 20px;
  color: red;
  font-size: 40px;
}

.why_content h3 {
  font-size: 30px;
  font-weight: 500;
}

.why_content p {
  line-height: 1.6;
  font-size: 1.1em;
  margin-bottom: 20px;
}

/* Why We Are */
.why_we_are {
  padding: 50px 0;
}

.why_we_are_main {
  width: 80%;
  margin: auto;
}

.why_we_are_head {
  width: 50%;
  margin: auto;
  text-align: center;
  padding-bottom: 50px;
}

.why_we_are_head h2 {
  color: red;
  font-size: 30px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.grid_box {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.three {
  grid-column: 1;
  grid-row: 1 / 5;
  background: url('../../../Assets/Gallery/14.webp') no-repeat center;
  background-size: cover;
}

.two {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}

.two h2,
.two p,
.five h2,
.five p {
  padding: 10px;
}

.five {
  grid-column: 2 / 4;
  grid-row: 3 / 5;
}

.triangle_topright {
  width: 0;
  height: 0;
  border-top: 30px solid red;
  border-right: 30px solid transparent;
}

@media only screen and (max-width: 900px) {
  .why_flex {
      flex-direction: column;
      padding: 20px;
  }

  .why_box {
      width: 100%;
      margin-bottom: 30px;
  }

  .why_content {
      padding: 0;
      text-align: center;
  }

  .why_we_are_head {
      width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .wrapper {
      display: flex;
      flex-wrap: wrap;
  }
}
