.calendar {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 90%;
  margin: 40px auto;
}

.calendar_head {
  padding: 20px;
  text-align: center;
  color: #EC1C24;
}


.calendar_head h1 {
  margin: 0;
  font-size: 2.5em;
  font-weight: bold;
}

.calendar_main {
  display: flex;
  justify-content: space-between;
  padding: 20px;

}

.calendar_container {
  flex: 1;
  min-width: 300px;
  margin-right: 20px;
}
.calendar_container img{
  width: 100%;
}
.events_list {
  flex: 1;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  min-width: 300px;
  margin-left: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.events_list img{
  width: 100%;
}
.events_list h2 {
  text-align: center;
  color: #EC1C24;
  font-weight: 500;
  margin-bottom: 20px;
}

.events_list ul {
  list-style: none;
  padding: 0;
}

.events_list li {
  margin: 10px 0;
  padding: 10px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}

.event_date {
  font-weight: bold;
  color: #EC1C24;
}

.event_name {
  color: #616161;
}

.legend {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.legend h3 {
  text-align: center;
  color: #EC1C24;
}

.legend ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.legend li {
  display: inline-block;
  margin-right: 20px;
  padding: 5px 10px;
}

.legend span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  border-radius: 100px;
}

.preBoard {
  background: orange;
}

.competition {
  background: #8F1E78;
}

.holiday {
  background: #FEF400;
}

.vacation {
  background: turquoise;
}

.exams {
  background: #00913E;
}

.olympiad {
  background: #f06292;
}

.highlight {
  background: #f06292 !important;
  color: white;
}

@media only screen and (max-width: 768px) {
  .calendar_main {
    flex-direction: column;
    width: 100%;
    margin: auto;
    text-align: center;
    justify-content: center;
    padding: 0;
  }

  .calendar_container, .events_list {
    width: 100%;
    margin-bottom: 20px;
  }

  .legend {
    margin-top: 0;
  }
  .events_list{
    margin: auto;
    min-width: 100%;
  }
}
