.teachers {
    width: 100%;
    font-family: Arial, sans-serif;
}

.teachers_head {
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg3.webp') no-repeat center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensure no background overflow */
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: linear-gradient(186deg, rgba(255,255,255,0.8), rgba(0,34,92,0.8)); /* Adjust gradient colors and transparency */
}

.teachers_head_text {
    position: relative;
    color: white;
    text-align: center;
    z-index: 1;
}

.teachers_head_text h1 {
    font-size: 3rem;
    margin: 0;
}

.teachers_main {
    width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
    background: #f5f5f5;
}

.teacher_main_head {
    text-align: center;
    padding: 0 20px;
    margin-bottom: 40px;
}

.teacher_main_head h2 {
    color: red; /* Adjust color as needed */
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.teacher_main_head p {
    line-height: 1.6;
    color: #333;
}

.teachersList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px 40px;
    margin: 20px;
}

.teacherCard {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 280px;
    height: auto;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
    border-radius: 10px;
}

.teacherCard:hover {
    transform: scale(1.05);
}

.teacherCard img {
    width: 100%;
    height: 250px; /* Adjust height as needed */
    object-fit: cover;
    border-radius: 10px;
}

.teacherCard h3 {
    margin: 10px 0;
    font-size: 1.2rem;
    color: #00225c; /* Adjust color as needed */
}

.teacherCard p {
    font-size: 1rem;
    color: #666;
}

@media only screen and (max-width: 768px) {
    .teachers_head_text h1 {
        font-size: 2rem;
    }
    .teacher_main_head h2 {
        font-size: 2rem;
    }
    .teacher_main_head p {
        font-size: 1rem;
    }
    .teacherCard {
        width: 90%;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .teachers_head {
        height: 60vh;
    }
    .teachers_head_text h1 {
        font-size: 1.5rem;
    }
    .teacher_main_head h2 {
        font-size: 1.5rem;
    }
    .teacher_main_head p {
        font-size: 0.9rem;
    }
    .teacherCard {
        width: 100%;
        height: auto;
    }
}
