.faculty {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: auto;
}

.hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
}

.grid {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    margin: auto;
    gap: 50px;
    justify-content: center;
}

.grid_box {
    max-width: 300px;
    padding: 20px;
    text-align: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    margin: auto;
}

.grid_box h3 {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
}

.grid_box p {
    color: #787878;
    line-height: 30px;
}

.grid_box img {
    width: 150px;
    border-radius: 100%;
}

.read_button {
    padding: 6px 15px;
    background-color: white;
    border-radius: 100px;
    color: black;
}

.read_button:hover {
    color: white;
    background-color: #EC1C24;
    border: none;
}

.read_button:active {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

@media only screen and (max-width: 768px) {
    .grid {
        width: 100%;
        grid-template-columns: 1fr;
        margin: auto;
        gap: 20px;
        justify-items: center;
    }

    .grid_box {
        margin: auto;
        width: 90%;
        max-width: none;
    }
}
