.salientfeatures{
    width: 100%;
    scroll-behavior: smooth;
}
.salientfeatures_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg3.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}
.salientfeatures_head_text{
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-wrap: nowrap;
}


.overlay_top{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.salientfeatures_main_head h1{
    color: red;
    padding-bottom: 50px;
}

.salientfeatures_main{
    width: 100%;
    margin-top: 50px;
    
}
.salientfeatures_main h1{
    text-align: center;
}
.salientfeatures_grid{
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}



.salientfeatures_grid_box {
    position: relative;
    display: inline-block;
    width: 400px;
    margin: auto;
}
.image_holder{
    position: relative;
}
.overlay{
    display: none;
    position: absolute;
    width: 100%;
    height: 98%;
    top: 0;
    z-index: 10;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 10%);
    border-radius: 10px;

}

.salientfeatures_grid_box img{
    width: 100%;
    border-radius: 10px;
}

.feature_text {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;
    z-index: 11;
    color: white;
}



.salientfeatures_grid_box:hover .feature_text {
    opacity: 1;
}
.salientfeatures_grid_box:hover .overlay{
    display: block;
    transition: opacity 2s ease;
  
}





@media only screen and (max-width:900px) {

    .salientfeatures_grid_box{
        width: 100%;
       
    }
    .salientfeatures_grid{
       
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
}