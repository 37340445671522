.indoor{
    width: 100%;
    scroll-behavior: smooth;
}

.indoor_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/Activities/indoor_activities.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.indoor_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}


.indoor_main{
    width: 100%;

}
.indoor_activities{
    width: 80%;
    margin: auto;
}
.indoor_activities h1{
    color: red;
}

.indoor_activities p{
    line-height: 30px;
}

.art_craft{
    display: flex;
    width: 80%;
    margin: auto;
    align-items: center;
    padding-top: 50px;
}
.art_frat_box{
    width: 100%;
    margin: auto 10px;
}
.art_frat_box img{
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.art_frat_box p{
    line-height: 35px;
}


@media only screen and (max-width:768px) {
    .art_craft{
        flex-direction: column;
    }
    .reverse{
        flex-direction: column-reverse;
    }
}