.blogList {
    padding: 20px;
    text-align: center;
  }
  
  .heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .blogCards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .blogCard {
    width: 30%;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .blogImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .blogTitle {
    font-size: 1.5rem;
    margin-top: 10px;
  }
  
  .blogDescription {
    font-size: 1rem;
    color: #555;
  }
  
  .readMore {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
  }
  
  .readMore:hover {
    text-decoration: underline;
  }
  