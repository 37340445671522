.teacherscorner{
    width: 100%;
    scroll-behavior: smooth;
}

.teacherscorner_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg6.webp') no-repeat center;
    background-size: cover;
    position: relative;
}
.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.teacherscorner_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}


.teacherscorner_main{
    width: 100%;
    height: 100vh;
}