/* Absolute.module.css */

.absolute_container {
    width: 100%;
    position: relative;
    height: 500px;
  }
  
  .absolute_content {
    position: absolute;
    width: 90%;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    z-index: 10;
  }
  
  .absolute_content_top {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .grid_box {
    margin: auto;
    width: 100%;
    padding: 10px;
  }
  
  .flex {
    display: flex;
    align-items: center;
    
  }
  
  .icon {
    padding: 20px;
    font-size: 40px;
    color: #ec1c24;
  }
  
  .text_matter h3 {
    font-size: 18px;
    font-weight: 500;
  }
  
  .text_matter p {
    font-size: 12px;
    color: #787878;
  }
  
  /* Bottom */
  .absolute_bottom {
    width: 100%;
  }
  
  .absolute_bottom_flex {
    display: flex;
    align-items: center;
  }
  
  .bottom_image_container {
    width: 50%;
    height: 60vh;
    background: url('../../../Assets/Home/04.webp') no-repeat center;
    background-size: cover;
  }
  
  .bottom_image_container img {
    width: 100%;
  }
  
  .bottom_content {
    width: 50%;
    height: 60vh;
    margin: auto;
    background: url('../../../Assets/Home/school.webp') no-repeat center;
    background-size: cover;
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
  }
  
  .margin_auto {
    margin: auto;
    width: 80%;
  }
  .margin_auto h2{
    font-size: 18px;
    color: white;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 69);
    opacity: 0.6;
    z-index: -10;
  }
  
  .bottom_content h1 {
    padding-bottom: 20px;
    color: white;
  }
  
  .bottom_content p {
    line-height: 30px;
    color: white;
  }
  
  .main_button {
    padding: 10px 30px;
    border-radius: 100px;
    outline: none;
    border: none;
    background-color: red;
    color: white;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
  
  .main_button:hover {
    background-color: rgb(106, 0, 0);
  }
  
  @media only screen and (max-width: 1090px) {
    .absolute_container {
      width: 100%;
      position: relative;
      margin: auto;
    }
    .absolute_content {
      top: 200px;
      width: 90%;
    }
    .bottom_content {
      z-index: 0;
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .absolute_container {
      width: 100%;
      position: relative;
      margin: auto;
    }
    .absolute_content {
      top: 200px;
      width: 90%;
    }
  }
  
  @media only screen and (max-width: 900px) {
    .absolute_container {
      width: 100%;
      height: auto;
      position: relative;
      margin: auto;
    }
  
    .absolute_content {
      width: 100%;
      position: static;
      height: auto;
      top: auto;
      left: auto;
      transform: none;
    }
  
    .absolute_bottom_flex {
      flex-direction: column;
      align-items: center;
    }
  
    .bottom_image_container {
      width: 100%;
    }
  
    .bottom_image_container img {
      width: 100%;
    }
  
    .bottom_content {
      width: 100%;
      padding: 20px;
      margin: auto;
    }
  
    .margin_auto {
      margin: auto;
      width: 100%;
    }
  }
  