.assessments{
    width: 100%;
    scroll-behavior: smooth;
}
.assessments_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg5.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;

}

.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.assessments_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-wrap: nowrap;
}


.assessments_mian{
    padding-top: 50px;
}
.assessments_fexbox{
    display: flex;
    width: 80%;
    margin: auto;
    align-items: center;
}

.assessments_box{
    width: 100%;
    margin:   auto 20px;
}
.assessments_box h2{
    font-size: 30px;
    color: red;
}
.assessments_box img{
    width: 100%;
}


@media only screen and (max-width:768px) {
        .assessments_fexbox{
            flex-direction: column;
            width: 90%;
        }
}