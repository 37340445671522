.outdoor{
width: 100%;
scroll-behavior: smooth;
}

.outdoor_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg6.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}
.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}
.outdoor_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}






.outdoor_main_head{
    padding: 100px;
}
.outdoor_main_head h1{
    color: red;
}

.outdoor_main_text{
    display: flex;
    width: 80%;
    margin: auto;
    align-items: center;
}

.outdoor_para{
    width: 100%;
}
.outdoor_img img{
    width: 80%;
    border-radius: 10px;
    
}
.outdoor_para p{
    padding: 10px;
    line-height: 40px;
}


.outdoor_images{
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px,2fr));
    gap: 10px;
}
.outdoor_images img{
    width: 100%;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}

@media only screen and (max-width:900px) {
    .outdoor_para p{
        line-height: 30px;
    }
}


@media only screen and (max-width:768px) {
    .outdoor_main_text{
        flex-direction: column;
        margin: auto;
    }
    .outdoor_main_head{
        padding: 0;
        padding-top: 50px;
        padding-bottom: 50px;
        margin: auto;
        width: 90%;
    }
    .outdoor_main_text{
        width: 90%;
    }
    .outdoor_img img{
        width: 100%;
    }
}