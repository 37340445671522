.gallery {
  width: 100%;
  scroll-behavior: smooth;
}

.gallery_head {
  width: 100%;
  height: 50vh;
  background: url('../../Assets/Home/school-2.webp') no-repeat center;
  background-size: cover;
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: rgb(255,255,255);
  background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.gallery_head_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 90%;
}

.category_selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.category_selector button {
  background: none;
  border: none;
  color: #ff0000;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-weight: 600;
}

.category_selector button:hover,
.category_selector .active {
  background: rgb(255,255,255);
  background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
  color: white;
}

.gallery_main {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  padding: 20px;
}

.gallery_item {
  overflow: hidden;
  position: relative;
}

.gallery_item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.gallery_item:hover img {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .gallery_head_text h1 {
    font-size: 2em;
  }

  .category_selector button {
    padding: 8px 15px;
    margin: 5px;
    font-size: 14px;
  }

  .gallery_main {
    width: 95%;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

@media (max-width: 480px) {
  .gallery_head {
    height: 40vh;
  }

  .gallery_head_text h1 {
    font-size: 1.8em;
  }

  .category_selector {
    align-items: center;
  }

  .category_selector button {
    padding: 6px 10px;
    margin: 3px 0;
    font-size: 12px;
  }

  .gallery_main {
    width: 100%;
    padding: 10px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .gallery_item img {
    height: auto;
  }
}
