.documents{
    width: 100%;
    scroll-behavior: smooth;
}
.documents_head{
    position: relative;
    background: url('../../../Assets/bg4.webp') no-repeat ;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 50vh;
}

.documents_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}


.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}


.documents_main{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.documents_list{
    width: 80%;
    margin: auto;
}
.documents_list h1{
    color: red;
}
.docs_list_flex{
    display: flex;
    align-items: center;
}

 .loader {
    width: 30px;
    aspect-ratio: 1;
    --_c:no-repeat radial-gradient(farthest-side,#ff0909 92%,#0000);
    background: 
      var(--_c) top,
      var(--_c) left,
      var(--_c) right,
      var(--_c) bottom;
    background-size: 10px 10px;
    animation: l7 1s infinite;
}

/* .loader {
   width: 20px;
  } */
  @keyframes l7 {to{transform: rotate(.5turn)}}

  

.documents_list_li li{
    margin: 20px ;
    padding: 20px;
    list-style: none;
    font-size: 20px;
}






@media only screen and (max-width:900px) {
    .documents_list_li li{
        font-size: 15px;
        padding: 10px;
    }
}









.border_bottom{
    width:100%;
    height: 5px;
    background-color: red;
    animation: leftToRight 2s forwards;
}

@keyframes leftToRight {
 0%{
    transform: translateX(-100%);

 }  
 100%{
    transform: translateX(0);
 }  
}



