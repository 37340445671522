.principal {
    width: 100%;
    padding: 20px 0;
    background-color: #f9f9f9;
}

.heading {
    text-align: center;
    font-size: 30px;
    color: red;
    margin-bottom: 20px;
}

.principal_flexbox {
    width: 80%;
    background-color: white;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    position: relative;
    margin-top: 50px;
    align-items: flex-start;
}

.principal_image {
    flex: 1;
    text-align: center;
}

.principal_image img {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.principal_image h3 {
    font-size: 24px;
    margin-bottom: 5px;
    color: #333;
}

.principal_image p {
    font-size: 20px;
    color: red;
}

.principal_message {
    flex: 2;
    display: flex;
    align-items: center;
}

.principal_message_box {
    width: 100%;
}

.principal_message_box h4 {
    color: red;
    margin-bottom: 10px;
}

.principal_message_box p {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}

.principal_flexbox::after,
.principal_flexbox::before {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: red;
    z-index: -1;
}

.principal_flexbox::after {
    top: -10px;
    left: -10px;
}

.principal_flexbox::before {
    bottom: -10px;
    right: -10px;
}

@media only screen and (max-width: 768px) {
    .principal_flexbox {
        flex-direction: column;
        width: 90%;
    }

    .principal_image img {
        width: 100%;
    }

    .principal_message {
        width: 100%;
    }
}
