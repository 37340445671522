.holiday {
    padding: 20px;
  }
  
  .holiday_head {
    width: 100%;
    height: 200px;
    background: url('../../../Assets/Home/school-2.webp') no-repeat center;
    background-size: cover;
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .holiday_head_text {
    position: absolute;
    color: #fff;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }



  
  .holiday h2{
    text-align: center;
    padding-top: 50px;
    font-weight: 500;
  }
  .holiday_main {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .holiday_table {
    width: 90%;
    max-width: 800px;
    margin: auto;
    border-collapse: collapse;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .holiday_table th, .holiday_table td {
    padding: 15px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .holiday_table th {
    background-color: #007bff;
    color: white;
  }
  
  .holiday_table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .holiday_table tr:hover {
    background-color: #f1f6f5;
  }
  
  @media (max-width: 600px) {
    .holiday_table th, .holiday_table td {
      padding: 10px;
    }
  
    .holiday_head_text h1 {
      font-size: 2em;
    }
  }