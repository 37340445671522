.studemts_corner{
    width: 100%;
    scroll-behavior: smooth;
}

.studemts_corner_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg6.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}
.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.studemts_corner_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}


.studemts_corner_main{
    width: 100%;
    padding-top: 50px;
}
.studemts_corner_main h1{
    text-align: center;
}

.studemts_corner_grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    width: 80%;
    margin: auto;
    gap: 20px;

}
.studemts_corner_grid_box{
    width: 300px;
    gap: 20px;
}

.grid_box_image img{
    width: 100%;
}