.offlineadmission{
    width: 100%;
    scroll-behavior: smooth;
}

.offlineadmission_head{
    width: 100%;
    height: 50vh;
    background: url('../../../Assets/bg4.webp') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}


.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: rgb(255,255,255);
    background: linear-gradient(186deg, rgba(255,255,255,1) 0%, rgba(0,34,92,1) 0);
}

.offlineadmission_head_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}




.offlineadmission_main{
    padding-top: 50px;
}
.offlineadmission_container{
    width: 80%;
    margin: auto;
}
.offlineadmission_container h1{
    color: red;
}
.offlineadmission_paragraph{
    padding-top: 50px;
    padding-bottom: 50px;
}
.border_bottom{
    width:100%;
    height: 5px;
    background-color: red;
    animation: leftToRight 2s forwards;
}

@keyframes leftToRight {
 0%{
    transform: translateX(-100%);

 }  
 100%{
    transform: translateX(0);
 }  
}