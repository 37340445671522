.blogDetail {
    max-width: 900px;
    margin: auto;
    padding: 20px;
  }
  
  .blogTitle {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .blogImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .blogContent {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  .blogParagraph {
    margin-bottom: 20px;
  }
  